import React from 'react'
import '../../catcss.css'
import cat from '../../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';

function Select() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    
    <div className="container margin-top">
        <h1>Select</h1>
        <p className="p-midb">Customize the native <code>&lt;select&gt;</code> with our css classes</p>
    </div>
    <div class="container">
    <main class="container">
    <div class="container">
    <h3>Default:</h3>
    <p>Custom <code>&lt;select&gt;</code> menus need only a custom class, .form-select to trigger the custom styles. Custom styles are limited to the <code>&lt;select&gt;</code>’s initial appearance and cannot modify the <code>&lt;option&gt;</code>s due to browser limitations.</p>
    <select class="form-select margin-bottom">
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
<p>Creating a selector already styled is as easy as using the <code>.form-select</code> class</p>
<div class="container">
<code className="code2 margin-bottom">&lt;select class="form-select"/&gt;<br></br>
&lt;option&gt; selected&gt;Open this select menu&lt;/option&gt;<br></br>
&lt;option&gt; value="1"&gt;One&lt;/option&gt;<br></br>
&lt;option&gt; value="2"&gt;Two&lt;/option&gt;<br></br>
&lt;option&gt; value="3"&gt;Three&lt;/option&gt;<br></br>
&lt;/select&gt;
</code>
</div>
</div>
<div class="container">
    <h3>Sizing:</h3>
    <p>You may also choose from small and large custom selects to match our similarly sized text inputs.</p>
    <select class="form-select sm margin-bottom">
  <option selected>This is a small select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
<p>If you want a small size selector, just use the <code>.form-select sm</code>class</p>
<div class="container">
<code className="code2">&lt;select class="form-select sm"/&gt;<br></br>
&lt;option&gt; selected&gt;This is a small select menu&lt;/option&gt;<br></br>
&lt;option&gt; value="1"&gt;One&lt;/option&gt;<br></br>
&lt;option&gt; value="2"&gt;Two&lt;/option&gt;<br></br>
&lt;option&gt; value="3"&gt;Three&lt;/option&gt;<br></br>
&lt;/select&gt;
</code>
</div>
<select class="form-select lg margin-bottom" aria-label="Default select example">
  <option selected>This is a large select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
<p>If you need a large size selector, use the <code>.form-select lg</code> class</p>
<div class="container">
<code className="code2 margin-bottom">&lt;select class="form-select lg"/&gt;<br></br>
&lt;option&gt; selected&gt;This is a large select menu&lt;/option&gt;<br></br>
&lt;option&gt; value="1"&gt;One&lt;/option&gt;<br></br>
&lt;option&gt; value="2"&gt;Two&lt;/option&gt;<br></br>
&lt;option&gt; value="3"&gt;Three&lt;/option&gt;<br></br>
&lt;/select&gt;
</code>
</div>
</div>
<div class="container margin">
    <h3>Disabled selector:</h3>
    <p>Add the <code>disabled</code> boolean attribute on a select to give it a grayed out appearance and remove pointer events.</p>
    <select class="form-select margin-bottom" disabled>
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
</main>
    </div>
    
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Select;