import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'

function Accordion() {
  return ( 
   <div className="container">
   <Helmet>
      <title>Catcss - Accordions</title>
      <meta name="description" content="Create Accordions with Catcss"></meta>
    </Helmet>
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Accordions</h1>
        <p className="p-midb">Examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms.</p>
    </div>
    <div className="container">
    <div className="container">
    <h3>Trying to make it simple:</h3>
    <p>In catcss we bet that everyone can design web pages without having to be an expert in development, so in this framework we move away from the use of Javascript, however, this makes the documentation a bit more complex, but don't worry !! Here is the code so that you can modify it to your preferences! Simply copy, paste and modify!</p>
    <nav class="accordion arrows">
		<header class="box bg-color green-300">
			<label for="acc-close" class="box-title">Catcss Accordion</label>
		</header>
		<input type="radio" name="accordion" id="cb1" />
		<section class="box">
			<label class="box-title" for="cb1">Open me!</label>
			<label class="box-close" for="acc-close"></label>
			<div class="box-content">Click on an item to open. Click on its header or the list header to close.</div>
		</section>
		<input type="radio" name="accordion" id="cb2" />
		<section class="box">
			<label class="box-title" for="cb2">Me too!</label>
			<label class="box-close" for="acc-close"></label>
			<div class="box-content">Add the class 'arrows' to nav.accordion to add dropdown arrows.</div>
		</section>
		<input type="radio" name="accordion" id="cb3" />
		<section class="box">
			<label class="box-title" for="cb3">uwu</label>
			<label class="box-close" for="acc-close"></label>
			<div class="box-content">Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Quisque finibus tristique nisi, maximus ullamcorper ante finibus eget.</div>
		</section>

		<input type="radio" name="accordion" id="acc-close" />
	</nav>
    </div>
    </div>
    <div className="container">
    <div className="container">
    <h3>Default and easy:</h3>
    <p>This version of an accordion made entirely with CSS is the easiest way to do it without having to use Js, to know how it has been done, open the different sections of the accordion!</p>
    <div className="container ">
	<details open>
		<summary>
			How easy is to create this accordion?
		</summary>
		<div>
			Super easy, you only have to use the tags <code>&lt;details&gt;</code> and <code>&lt;summary&gt;</code> this ones are supported natively for your browser.
		</div>
	</details>
	<details>
		<summary>
			Can i customize the accordion?
		</summary>
		<div className="bg-color blue-100">
			Of course!, in this case we have only used the <code>bg-color blue-100</code> class and you have a blue background! Super easy!
		</div>
	</details>
	<details>
		<summary>
			What type of content can I have inside one of these?
		</summary>
		<div>
			Almost anything you'd like. The <code>&lt;details&gt;</code> element allows all flow content, which is basically everything.
		</div>
	</details>
	<details>
		<summary>
			How does it work?
		</summary>
		<div>
			The <code>&lt;details&gt;</code> element encapsulates the <code>&lt;summary&gt;</code> element. The <code>&lt;summary&gt;</code> becomes the 'label' for the <code>&lt;details&gt;</code> and acts like a button. When clicked, the attribute <code>open</code> is added to the <code>&lt;details&gt;</code> element, making it display. You can therefore style the open and closed states seperately if you'd like.
		</div>
	</details>
</div>
    </div>
    </div>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Accordion;