import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Map from './components/Roadmap/Map'
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Typography from './components/Documentation/Typography/Typography';
import Buttons from './components/Documentation/Buttons/Buttons';
import Form from './components/Documentation/Form/FormSections/Form';
import Select from './components/Documentation/Form/FormSections/Select';
import Range from './components/Documentation/Form/FormSections/Range';
import Checkbox from './components/Documentation/Form/FormSections/Checkbox';
import FormMenu from './components/Documentation/Form/FormMenu';
import Colors from './components/Documentation/Colors/Colors';
import Backers from './components/Backers/Backers';
import Team from './components/Team/Team';
import Apply from './components/Apply/Apply';
import GetStarted from './components/Getstarted/Getstarted';
import News from './components/News/News';
import Images from './components/Documentation/Images/Images';
import ContentMenu from './components/Documentation/Content/ContentMenu';
import Tables from './components/Documentation/Tables/Tables';
import ComponentMenu from './components/Documentation/CssComponents/ComponentMenu';
import Accordion from './components/Documentation/CssComponents/Accordion';
import Utilities from './components/Documentation/Utilities/Utilities';
import Alerts from './components/Documentation/CssComponents/Alerts'
import Badges from './components/Documentation/CssComponents/Badges'
import Cards from './components/Documentation/CssComponents/Cards'
import Closebutton from './components/Documentation/CssComponents/Closebutton'
import Spinners from './components/Documentation/CssComponents/Spinners'


ReactDOM.render(

  <Router>

     <Switch>

      <Route exact path="/" component={App}/>
      
      <Route exact path="/Map" component={Map}/>
      <Route exact path="/ContentMenu" component={ContentMenu}/>
      <Route exact path="/Typography" component={Typography}/>
      <Route exact path="/Buttons" component={Buttons}/>
      <Route exact path="/Form" component={Form}/>
      <Route exact path="/Select" component={Select}/>
      <Route exact path="/Range" component={Range}/>
      <Route exact path="/Checkbox" component={Checkbox}/>
      <Route exact path="/FormMenu" component={FormMenu}/>
      <Route exact path="/Colors" component={Colors}/>
      <Route exact path="/Backers" component={Backers}/>
      <Route exact path="/Team" component={Team}/>
      <Route exact path="/Apply" component={Apply}/>
      <Route exact path="/GetStarted" component={GetStarted}/>
      <Route exact path="/News" component={News}/>
      <Route exact path="/Images" component={Images}/>
      <Route exact path="/Tables" component={Tables}/>
      <Route exact path="/ComponentMenu" component={ComponentMenu}/>
      <Route exact path="/Utilities" component={Utilities}/>
      <Route exact path="/Accordion" component={Accordion}/>
      <Route exact path="/Alerts" component={Alerts}/>
      <Route exact path="/Badges" component={Badges}/>
      <Route exact path="/Cards" component={Cards}/>
      <Route exact path="/Closebutton" component={Closebutton}/>
      <Route exact path="/Spinners" component={Spinners}/>



    </Switch>

  </Router>,

  document.getElementById('root')

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
