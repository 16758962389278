import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import {BiHeading} from 'react-icons/bi'
import {FaParagraph} from 'react-icons/fa'
import {FaList} from 'react-icons/fa'
import {ImFontSize} from 'react-icons/im'
import { Link } from 'react-router-dom';

function Typography() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Typography</h1>
        <p className="p-midb">Modifying the typography of your page to your liking has never been so easy!</p>
    </div>
    <div className="container">
    <main class="grid col-3 mid-col-2 small-col-1">
   
            <div class="card">
            <BiHeading size="2em" color="#47c5ae"/><h2>Headings</h2>
            <p>Different heading elements</p>
            </div>
            
            
            <div class="card">
            <FaParagraph size="2em" color="#47c5ae"/><h2>Paragraph</h2>
            <p>Lead paragraph and inline text elements</p>
            </div>
          
            <div class="card">
            <ImFontSize size="2em" color="#47c5ae"/><h2>Responsive fonts</h2>
            <p>Scale fonts more naturally across device and viewport sizes</p>
            </div>
        </main>
    </div>
    <div className="container ">
    <h2 className="color-aqua">Display Headings</h2>
        <p>Traditional heading elements are designed to work best in the meat of your page content. When you need a
            heading to stand out, consider using a display heading—a larger, slightly more opinionated heading style.
        </p>
        <div className="container">
        <div className="container-center grid col-1">
        <p class="display-1 ">Display 1</p>
        <p class="display-2 ">Display 2</p>
        <p class="display-3 ">Display 3</p>
        <p class="display-4 ">Display 4</p>
        <p class="display-5 ">Display 5</p>
        <p class="display-6 ">Display 6</p>
        </div>
        </div>
    </div>
    <div class="container">
        <code>&lt;p&gt; class="display-1 ">Display 1&lt;/p&gt;;</code> <br></br>
        <code>&lt;p&gt; class="display-1 ">Display 2&lt;/p&gt;;</code><br></br>
        <code>&lt;p&gt; class="display-1 ">Display 3&lt;/p&gt;;</code><br></br>
        <code>&lt;p&gt; class="display-1 ">Display 4&lt;/p&gt;;</code><br></br>
        <code>&lt;p&gt; class="display-1 ">Display 5&lt;/p&gt;;</code><br></br>
        <code>&lt;p&gt; class="display-1 ">Display 6&lt;/p&gt;;</code>
        </div>   
    <div className="container margin-top">
    <h2 className="color-aqua">Headings</h2>
        <p>All HTML headings, &lt;h1&gt;  through &lt;h6&gt; , are available.
        </p>
        </div>
    <div class="container">
        <h1>Heading H1</h1>
        <h2>Heading H2</h2>
        <h3>Heading H3</h3>
        <h4>Heading H4</h4>
        <h5>Heading H5</h5>
        <h6>Heading H6</h6>
    </div>
    <div class="container">
        <code>&lt;h1&gt;>Heading H1&lt;/h1&gt;;</code> <br></br>
        <code>&lt;h2&gt;>Heading H2&lt;/h2&gt;;</code> <br></br>
        <code>&lt;h3&gt;>Heading H3&lt;/h3&gt;;</code> <br></br>
        <code>&lt;h4&gt;>Heading H4&lt;/h4&gt;;</code> <br></br>
        <code>&lt;h5&gt;>Heading H5&lt;/h5&gt;;</code> <br></br>
        <code>&lt;h6&gt;>Heading H6&lt;/h6&gt;;</code> <br></br>
        </div>
        <div className="container margin-top">
    <h2 className="color-aqua">Inline text elements</h2>
        <p>Styling for common inline HTML5 elements.</p>
        </div>
        <div class="container">
        <p>You can use the mark tag to <mark>highlight</mark> text.</p>
        <p class="text-decoration-line-through">This line of text is meant to be treated as deleted text.</p>
        <p class="text-decoration-underline">This line of text will render as underlined.</p>
        <p class="small">This line of text is meant to be treated as fine print.</p>
        <p class="text-muted">This line of text is a muted text.</p>
    </div>
    <div class="container">
        <code>&lt;p&gt;You can use the mark tag to &lt;mark&gt;highlight&lt;/mark&gt; text.&lt;/p&gt;</code><br></br>
        <code>&lt;p class="text-decoration-line-through"&gt;This line of text is meant to be treated as deleted text.&lt;/p&gt;</code><br></br>
        <code>&lt;p class="text-decoration-underline"&gt;This line of text will render as underlined.&lt;/p&gt;</code><br></br>
        <code>&lt;p class="small"&gt;This line of text is meant to be treated as fine print.&lt;/p&gt;</code><br></br>
        <code>&lt;p class="text-muted"&gt;This line of text is a muted text.&lt;/p&gt;</code>
    </div>
    <div class="container">
    <p>Beware that those tags should be used for semantic purpose:</p>
    <ul>
        <li><code>&lt;mark&gt;</code>represents text which is marked or highlighted for reference or notation purposes.</li>
        <li><code>&lt;small&gt;</code>represents side-comments and small print, like copyright and legal text.</li>
        <li><code>&lt;s&gt;</code> represents element that are no longer relevant or no longer accurate.</li>
        <li><code>&lt;u&gt;</code> represents a span of inline text which should be rendered in a way that indicates that it has a non-textual annotation.</li>
    </ul>
    </div>
    <div className="container">
    <p>If you want to style your text, you should use the following classes instead:</p>
    <ul>
        <li><code>.mark</code>will apply the same styles as <code>&lt;mark&gt;</code></li>
        <li><code>.small</code>will apply the same styles as <code>&lt;small&gt;</code></li>
        <li><code>.text-decoration-underline</code> will apply the same styles as <code>&lt;u&gt;</code></li>
        <li><code>.text-decoration-line-through</code> will apply the same styles as <code>&lt;s&gt;</code></li>
    </ul>
    </div>
    <div className="container">
    <h2 className="color-aqua">Responsive font sizes</h2>
        <p>In Catcss we have adapted all our code to be able to give the best performance on any device, that is why our fonts have different configurations depending on the size of the screen.
        </p>
        </div>
        <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Typography;
