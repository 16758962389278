import React from 'react'
import '../../catcss.css';
import cat from '../../images/logocontitulo(1).png'
import {ContactUs} from '../SendEmail/sendEmail.js';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';


function Apply() {
    return ( 
     <div className="container">
     <Helmet>
      <title>Catcss - Apply for alpha</title>
      <meta name="description" content="Apply here to join the alpha December test"></meta>
    </Helmet>
      <div className="container container-flexend ">
      <Link to="/">
        <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
        </Link>
      </div>
      <div className="container margin-top">
        <h1>Apply to access the alpha</h1> 
        <p className="p-midb">Write to us why you want to access the December alpha and we will provide you with access to it!</p>
    </div>
    <ContactUs/>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
      </div>
    )
  }
  
  export default Apply;