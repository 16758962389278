import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3rqjb9c', 'template_62gxje4', e.target, 'user_FtkVdaU7eR5cbzatsqamY')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
      <div>
    <div class="container-form card2 span-1 mid-span-3">
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input class="input" type="text" name="user_name" placeholder="Insert your name"/>
      <label>Email</label>
      <input class="input"  type="email" name="user_email" placeholder="insert your email address" />
      <label>Message</label>
      <textarea class="margin-bottom" name="message" placeholder="Insert message" />
      <input class="input"  class="btn green" type="submit" value="Send" />
    </form>
    </div>
    </div>
  );
};
