import React from 'react'
import Landing from './components/Landing/landing';

function App() {
  return (
    <Landing />
  )
}

export default App;
