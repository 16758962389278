import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'

function Badges() {
  return ( 
   <div className="container">
   <Helmet>
      <title>Catcss - Badges</title>
      <meta name="description" content="Create Badges with Catcss"></meta>
    </Helmet>
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Badges</h1>
        <p className="p-midb">Documentation and examples for badges, our small count and labeling component.</p>
    </div>
    <div className="container">
    <div className="container">
    <h3>Basic badges</h3>
    <p>The following badges are the basic ones you can create using the class <code>badge</code> </p>
    </div>
    <div class="container-bd-example">
     <span class="badge primary">Primary</span>&nbsp; 
    <span class="badge secondary">Secondary</span>&nbsp; 
    <span class="badge success">Success</span>&nbsp; 
    <span class="badge danger">Danger</span>&nbsp; 
    <span class="badge warning">Warning</span>&nbsp; 
    <span class="badge info">Info</span> 
    </div>
    <div class="container">
    <p><code>&lt;span class="badge primary">Primary&lt;/span></code></p>
    <p><code>&lt;span class="badge secondary">Secondary&lt;/span></code></p>
    <p><code>&lt;span class="badge success">Success&lt;/span></code></p>
    <p><code>&lt;span class="badge danger">Danger&lt;/span></code></p>
    <p><code>&lt;span class="badge warning">Warning&lt;/span></code></p>
    <p><code>&lt;span class="badge info">Info&lt;/span></code></p>
    </div>
    </div>
    <div className="container">
    <div className="container">
    <h3>Rounded badges</h3>
    <p>These examples are using the <code>rounded</code> class together with the <code>badge</code> class  </p>
    </div>
    <div class="container-bd-example">
     <span class="badge primary rounded">Primary</span>&nbsp; 
    <span class="badge secondary rounded">Secondary</span>&nbsp; 
    <span class="badge success rounded">Success</span>&nbsp; 
    <span class="badge danger rounded">Danger</span>&nbsp; 
    <span class="badge warning rounded">Warning</span>&nbsp; 
    <span class="badge info rounded">Info</span> 
    </div>
    <div class="container">
    <p><code>&lt;span class="badge primary rounded">Primary&lt;/span></code></p>
    <p><code>&lt;span class="badge secondary rounded">Secondary&lt;/span></code></p>
    <p><code>&lt;span class="badge success rounded">Success&lt;/span></code></p>
    <p><code>&lt;span class="badge danger rounded">Danger&lt;/span></code></p>
    <p><code>&lt;span class="badge warning rounded">Warning&lt;/span></code></p>
    <p><code>&lt;span class="badge info rounded">Info&lt;/span></code></p>
    </div>
    </div>
    <div className="container">
    <div className="container">
    <h3>Buttons</h3>
    <p>Badges can be used as part of links or buttons to provide a counter.</p>
    </div>
    <div class="container-bd-example">
    <button type="button" class="btn dark">
  Notifications <span class="badge danger">10</span>
</button>
    </div>
    <div class="container">
    <p><code>&lt;button class="btn dark">Notifications&lt;span class=" badge danger">10&lt;/span></code></p>
    </div>
    </div>
    <div className="container">
    <div className="container">
    <h3>Customize your badges</h3>
    <p>In Catcss customizing any element is very easy, just use the colors from our color table and customize the badges as you want!</p>
    </div>
    <div class="container-bd-example">
    <span class="badge bg-color red-400">bg-color red-400</span>&nbsp; 
    <span class="badge bg-color yellow-600 txt-dark">bg-color yellow-600</span>&nbsp; 
    <span class="badge bg-color green-500">bg-color green-500</span>&nbsp; 
    </div>
    <div class="container">
    <p><code>&lt;span class="badge bg-color red-400">bg-color red-400&lt;/span></code></p>
    <p><code>&lt;span class="badge bg-color yellow-600 txt-dark">bg-color yellow-600&lt;/span></code></p>
    <p><code>&lt;span class="badge bg-color green-500">bg-color green-500&lt;/span></code></p>
    </div>
    </div>
    
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Badges;