import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {MdFormatColorText} from 'react-icons/md'

function Colors() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Colors</h1>
        <p className="p-midb">Customize your text and backgrounds with our extense palette of colors.</p>
    </div>
    <div class="container">
    <div className="">
    <h2 className="color-aqua">Background colors:</h2>
        </div>
    <div class="container">
       <h3>Red background color:</h3>
        <main class="grid col-5 mid-col-2 small-col-1">
        <div>
        <code className="code2">bg-color red-50</code>
        <div class="card2 bg-color red-50"></div>
    </div>
    <div>
        <code className="code2">bg-color red-100</code>
        <div class="card2 bg-color red-100"></div>
    </div>
    <div>
        <code className="code2">bg-color red-200</code>
        <div class="card2 bg-color red-200"></div>
    </div>
    <div>
        <code className="code2">bg-color red-300</code>
        <div class="card2 bg-color red-300"></div>
    </div>
    <div>
        <code className="code2">bg-color red-400</code>
        <div class="card2 bg-color red-400"></div>
    </div>
    <div>
        <code className="code2">bg-color red-500</code>
        <div class="card2 bg-color red-500"></div>
    </div>
    <div>
        <code className="code2">bg-color red-600</code>
        <div class="card2 bg-color red-600"></div>
    </div>
    <div>
        <code className="code2">bg-color red-700</code>
        <div class="card2 bg-color red-700"></div>
    </div>
    <div>
        <code className="code2">bg-color red-800</code>
        <div class="card2 bg-color red-800"></div>
    </div>
    <div>
        <code className="code2">bg-color red-900</code>
        <div class="card2 bg-color red-900"></div>
    </div>
        </main>
    </div>
    <div class="container">
       <h3>Green background color:</h3>
        <main class="grid col-5 mid-col-2 small-col-1">
        <div>
        <code className="code2">bg-color blue-50</code>
        <div class="card2 bg-color green-50"></div>
    </div>
    <div>
        <code className="code2">bg-color green-100</code>
        <div class="card2 bg-color green-100"></div>
    </div>
    <div>
        <code className="code2">bg-color green-200</code>
        <div class="card2 bg-color green-200"></div>
    </div>
    <div>
        <code className="code2">bg-color green-300</code>
        <div class="card2 bg-color green-300"></div>
    </div>
    <div>
        <code className="code2">bg-color green-400</code>
        <div class="card2 bg-color green-400"></div>
    </div>
    <div>
        <code className="code2">bg-color green-500</code>
        <div class="card2 bg-color green-500"></div>
    </div>
    <div>
        <code className="code2">bg-color green-600</code>
        <div class="card2 bg-color green-600"></div>
    </div>
    <div>
        <code className="code2">bg-color green-700</code>
        <div class="card2 bg-color green-700"></div>
    </div>
    <div>
        <code className="code2">bg-color green-800</code>
        <div class="card2 bg-color green-800"></div>
    </div>
    <div>
        <code className="code2">bg-color green-900</code>
        <div class="card2 bg-color green-900"></div>
    </div>
        </main>
    </div>
    <div class="container">
       <h3>Blue background color:</h3>
        <main class="grid col-5 mid-col-2 small-col-1">
        <div>
        <code className="code2">bg-color blue-50</code>
        <div class="card2 bg-color blue-50"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-100</code>
        <div class="card2 bg-color blue-100"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-200</code>
        <div class="card2 bg-color blue-200"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-300</code>
        <div class="card2 bg-color blue-300"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-400</code>
        <div class="card2 bg-color blue-400"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-500</code>
        <div class="card2 bg-color blue-500"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-600</code>
        <div class="card2 bg-color blue-600"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-700</code>
        <div class="card2 bg-color blue-700"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-800</code>
        <div class="card2 bg-color blue-800"></div>
    </div>
    <div>
        <code className="code2">bg-color blue-900</code>
        <div class="card2 bg-color blue-900"></div>
    </div>
        </main>
    </div>
    <div class="container">
       <h3>Pink background color:</h3>
        <main class="grid col-5 mid-col-2 small-col-1">
        <div>
        <code className="code2">bg-color pink-50</code>
        <div class="card2 bg-color pink-50"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-100</code>
        <div class="card2 bg-color pink-100"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-200</code>
        <div class="card2 bg-color pink-200"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-300</code>
        <div class="card2 bg-color pink-300"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-400</code>
        <div class="card2 bg-color pink-400"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-500</code>
        <div class="card2 bg-color pink-500"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-600</code>
        <div class="card2 bg-color pink-600"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-700</code>
        <div class="card2 bg-color pink-700"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-800</code>
        <div class="card2 bg-color pink-800"></div>
    </div>
    <div>
        <code className="code2">bg-color pink-900</code>
        <div class="card2 bg-color pink-900"></div>
    </div>
        </main>
    </div>
    <div>
    <h2 className="color-aqua">Text colors:</h2>
        </div>
       <div class="container">
       <h3>Red text color:</h3>
       </div>
        <div className="container grid col-5 mid-col-2 small-col-1">
        
        <div>
        <code className="code2">txt-color red-50</code>
        <p className="lead txt-color red-50">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-100</code>
        <p className="lead txt-color red-100">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-200</code>
        <p className="lead txt-color red-200">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-300</code>
        <p className="lead txt-color red-300">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-400</code>
        <p className="lead txt-color red-400">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-500</code>
        <p className="lead txt-color red-500">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-600</code>
        <p className="lead txt-color red-600">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-700</code>
        <p className="lead txt-color red-700">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-800</code>
        <p className="lead txt-color red-800">This is a red colored text</p>
    </div>
    <div>
        <code className="code2">txt-color red-900</code>
        <p className="lead txt-color red-900">This is a red colored text</p>
    </div>
     </div>
     <div class="container">
       <h3>Green text color:</h3>
       </div>
     <div className="container grid col-5 mid-col-2 small-col-1">
        <div>
        <code className="code2">txt-color green-50</code>
        <p className="lead txt-color green-50">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-100</code>
        <p className="lead txt-color green-100">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-200</code>
        <p className="lead txt-color green-200">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-300</code>
        <p className="lead txt-color green-300">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-400</code>
        <p className="lead txt-color green-400">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-500</code>
        <p className="lead txt-color green-500">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-600</code>
        <p className="lead txt-color green-600">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-700</code>
        <p className="lead txt-color green-700">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-800</code>
        <p className="lead txt-color green-800">This is a green colored text</p>
    </div>
    <div>
        <code className="code2">txt-color green-900</code>
        <p className="lead txt-color green-900">This is a green colored text</p>
    </div>
     </div>
     <div class="container">
       <h3>Blue text color:</h3>
       </div>
     <div className="container grid col-5 mid-col-2 small-col-1">
        <div>
        <code className="code2">txt-color blue-50</code>
        <p className="lead txt-color blue-50">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-100</code>
        <p className="lead txt-color blue-100">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-200</code>
        <p className="lead txt-color blue-200">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-300</code>
        <p className="lead txt-color blue-300">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-400</code>
        <p className="lead txt-color blue-400">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-500</code>
        <p className="lead txt-color blue-500">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-600</code>
        <p className="lead txt-color blue-600">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-700</code>
        <p className="lead txt-color blue-700">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-800</code>
        <p className="lead txt-color blue-800">This is a blue colored text</p>
    </div>
    <div>
        <code className="code2">txt-color blue-900</code>
        <p className="lead txt-color blue-900">This is a blue colored text</p>
    </div>
     </div>
     <div class="container">
       <h3>Pink text color:</h3>
       </div>
     <div className="container grid col-5 mid-col-2 small-col-1">
        <div>
        <code className="code2">txt-color pink-50</code>
        <p className="lead txt-color pink-50">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-100</code>
        <p className="lead txt-color pink-100">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-200</code>
        <p className="lead txt-color pink-200">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-300</code>
        <p className="lead txt-color pink-300">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-400</code>
        <p className="lead txt-color pink-400">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-500</code>
        <p className="lead txt-color pink-500">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-600</code>
        <p className="lead txt-color pink-600">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-700</code>
        <p className="lead txt-color pink-700">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-800</code>
        <p className="lead txt-color pink-800">This is a pink colored text</p>
    </div>
    <div>
        <code className="code2">txt-color pink-900</code>
        <p className="lead txt-color pink-900">This is a pink colored text</p>
    </div>
     </div>
    </div>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Colors;