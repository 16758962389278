import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'

function Closebutton() {
  return ( 
   <div className="container">
   <Helmet>
      <title>Catcss - Close button</title>
      <meta name="description" content="Create close buttons with Catcss"></meta>
    </Helmet>
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Cards</h1>
        <p className="p-midb">Documentation and examples for badges, our small count and labeling component.</p>
    </div>
    <div className="container">
    <div className="container">
    <h3>Default</h3>
    <p>The following close button is made using class <code>btn-close</code> </p>
    </div>
    <div className="container-bd-example">
    <button type="button" class="btn-close"></button>
    </div>
    </div>
    <div className="container">
    <div className="container">
    <h3>Disabled close button</h3>
    <p>The following close button is in a disabled state already customized by Catcss team, just use the <code>disabled</code> value. </p>
    </div>
    <div className="container-bd-example">
    <button type="button" class="btn-close" disabled></button>
    </div>
    </div>
    
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Closebutton;