import React from 'react'
import '../../catcss.css';
import cat from '../../images/logocontitulo(1).png'
import logo from '../../images/catcssavatar(1).png'
import world from '../../images/world.svg'
import developer from '../../images/developer.svg'
import designer from '../../images/designer.svg'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'

function News() {
    return ( 
     <div className="container">
     <Helmet>
      <title>Catcss - What's new in Catcss</title>
      <meta name="description" content="Stay up to date with the latest news and updates from catcss"></meta>
    </Helmet>
      <div className="container container-flexend ">
      <Link to="/">
        <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
        </Link>
      </div>
      <div className="container margin-top">
        <h1>What's new in Catcss</h1>
        <p className="p-midb">Stay up to date with the latest updates and news about Catcss</p>
    </div>
    <div className="container">
    <h3 className="color-aqua">20/10/2021 - A little update! </h3>
    <p>OThe development of the framework and the documentation does not stop, I am trying to prepare the best possible version of Catcss for its launch, with the largest number of elements, components and utilities that time allows me to do, the web page is being updated with small improvements and small additions, although the biggest changes will arrive around December 22, the date of alpha release.
      <br></br>
      I leave you an example of how badges are made in Catcss </p>
      <div class="container-bd-example">
     <span class="badge primary">Primary</span>&nbsp; 
    <span class="badge secondary">Secondary</span>&nbsp; 
    <span class="badge success">Success</span>&nbsp; 
    <span class="badge danger">Danger</span>&nbsp; 
    <span class="badge warning">Warning</span>&nbsp; 
    <span class="badge info">Info</span> 
    </div>
    <div class="container-bd-example">
    <p><code>&lt;span class="badge primary">Primary&lt;/span></code></p>
    <p><code>&lt;span class="badge secondary">Secondary&lt;/span></code></p>
    <p><code>&lt;span class="badge success">Success&lt;/span></code></p>
    <p><code>&lt;span class="badge danger">Danger&lt;/span></code></p>
    <p><code>&lt;span class="badge warning">Warning&lt;/span></code></p>
    <p><code>&lt;span class="badge info">Info&lt;/span></code></p>
    </div>
</div>
    <div className="container">
    <h3 className="color-aqua">13/10/2021 - A framework for everyone </h3>
    <p>One of the things I want to achieve with Catcss is to be able to bring web design closer to developers who are beginners, obviously this framework can be better used by an experienced user, but a beginner can design with this framework easily and intuitively. Trying that the framework has all the components that a web design could have makes its development complicated. One of the pillars of our project is that it does not need the use of Javascript, this creates the inconvenience that some of the components that you can use in the design of your page would normally use Js for its operation, we have created these components using purely CSS This makes the code of these components somewhat more complex in terms of the classes to use, but obviously everything will be explained in the documentation so that it can be used easily.
      <br></br>
      <br></br>
      We are getting closer and closer to the stipulated date for the launch of our alpha and this makes me very happy! I really want you to try my framework and receive your feedback to continue making it grow and improve it.</p>
<div className="container-bd-example card2">
<img className="img-resp" width src={developer} alt="webdesigner"/> 
</div>
</div>
    <div className="container">
    <h3 className="color-aqua">09/10/2021 </h3>
    <p>Something that I will never tire of saying is how wonderful the developer community is, from the first moment that I have been commenting on <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">Twitter</a> about the project I am carrying out and how excited I am about it, I have only received words from people who want to help and who is also excited to see how this project evolves.
      <br></br>
      <br></br>
      The other day <a className="txt-color green-400" href="https://twitter.com/dinomitron">Shaun the Tech T-Rex</a>  supported me by buying me a <a className="txt-color green-400" href="https://www.buymeacoffee.com/ruizsantaclara">slice of pizza</a>  and brightening my day with words of kindness and sharing my project on his twitter account for the fact that he liked it. Many times I think that we are not really aware of the power that our actions and words can have, but I can assure you that all the good vibes that at least I receive within this community what they achieve is to inspire me to be a better person with others too and to continue fighting to achieve my goal, thank you very much to all of you who are interested in this, you are the fuel that ignites my motivation every day.  </p>
<div className="container-bd-example card2">
<img className="img-resp" width src={designer} alt="webdesigner"/> 
</div>
</div>
    <div className="container">
    <h3 className="color-aqua">05/10/2021 </h3>
    <p>Today has been an intense day since the morning, I have added more content to the documentation, although it is something that you cannot see yet, the documentation not only tries to be as easy to understand as possible, but I am also making it very visual which will facilitate its use by developers of all levels.
      <br></br>
      <br></br>
I have also decided to add translation to several languages for the page, its documentation and thus be able to reach many more developers around the world, English, Spanish, Chinese and German are for now the languages chosen for this, with the possibility of expanding the number of languages available in the future. I am sure that the community that we will create will be able to bring this design tool to everyone, this belongs to everyone! </p>
<div className="container-bd-example card2">
<img className="img-resp"  src={world} alt="womanworld"/> 
</div>
</div>
    <div className="container">
    <h3 className="color-aqua">04/10/2021 </h3>
    <p>Hello! This is the first entry in this section in which I will tell you about the progress and development of Catcss, a project that was born from the idea of making web design an easy and intuitive task, not only for experienced developers, but also for beginners. .
This project is something that excites me and motivates me every day to continue advancing and developing not only the code but also me as a developer and as a person, I hope that when the first versions with open source are available to all of you, you will not only enjoy it but also that you can contribute to this project by contributing your ideas, knowledge and concerns. Because Catcss does not pretend to be the most complex framework in the world, it aims to be the most accessible to everyone, so thanks in advance and see you soon!</p>
<div className="container-bd-example card2">
<img className="img-resp"  src={logo} alt="logocatcss"/> 
</div>
</div>
<div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
      </div>
    )
  }
  
  export default News;