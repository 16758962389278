import React from 'react'
import "../../catcss.css";
import cat from "../../images/logocontitulo(1).png";
import performance from "../../images/performance.svg"
import translation from "../../images/translation.svg"
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { MdCompareArrows } from "react-icons/md";
import { SiJavascript } from "react-icons/si";
import { SiSvelte } from "react-icons/si";
import { GiCubes } from "react-icons/gi";
import { FaGithubAlt} from "react-icons/fa";
import { IoSchool } from "react-icons/io5";
import { IoIosSpeedometer } from "react-icons/io";
import { FaReact } from "react-icons/fa";
import { FaVuejs } from "react-icons/fa";
import { FaAngular } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";


function Landing() {
  return (
    <div className="container">
    <Helmet>
      <title>Catcss CSS Framework</title>
      <meta name="description" content="Start using the best css framework"></meta>
    </Helmet>
      <div className="container container-flexend ">
        <img className="img-small" src={cat} alt="catcss" />
      </div>
      <div className="container">
        <h1 className="h1-f">
          Cats are always trying to sleep as much as they can, build websites
          fast and go to sleep!  [^._.^]ﾉ彡
        </h1>
        <p className="p-midb">
          The easiest to use CSS framework that saves you time!
        </p>
      </div>
      <div className="container margin-bottom">
      <Link to="GetStarted">
            <button className="btn dark">
              &#160;Get Started
            </button>
            </Link>
      </div>
      <div className="container margin-top">
        <main class="grid col-4 mid-col-2 small-col-1">
          <div class="card">
            <MdCompareArrows size="2em" color="#47c5ae" />
            <h2>Responsive</h2>
            <p>Designed for mobile</p>
          </div>
          <div class="card">
            <SiJavascript size="2em" color="#47c5ae" />
            <h2>No Javascript needed</h2>
            <p>Pure CSS, integrates with any JS environment</p>
          </div>
          <div class="card">
            <GiCubes size="2em" color="#47c5ae" />
            <h2>Modular</h2>
            <p>No needed to use the whole CSS file, use what you need!</p>
          </div>
          <div class="card">
            <FaGithubAlt size="2em" color="#47c5ae" />
            <h2>Open Source</h2>
            <p>
              {" "}
              Access to our repository and collaborate or play with the code ;)
            </p>
          </div>
        </main>
      </div>
      <div className="container grid col-2 mid-col-1 small-col-1 margin-top">
        <div>
          <IoSchool size="2em" color="#47c5ae" />
          <h2 className="">Super easy to learn</h2>
          <p className="p-midb">The framework designed to create your design in minutes, even for beginners!</p>
        </div>
        <div className="container-center">
          <div className="margin-bottom">
            <div className="margin-bottom">
              <code>btn</code>
            </div>
            <button class="btn">Button</button>
          </div>
          <div className="margin-bottom">
            <div className="margin-bottom">
              <code>btn green</code>
            </div>
            <button class="btn green ">Button green</button>
          </div>
          <div className="margin-bottom">
            <div className="margin-bottom">
              <code>btn pink</code>
            </div>
            <button class="btn pink ">Button pink</button>
          </div>
        </div>
      </div>
      <div className="container margin-top grid col-2 mid-col-1 small-col-1">
        <div>
          <SiJavascript size="2em" color="#47c5ae" />
          <h2 className="">No need of Javascript!</h2>
          <p className="p-midb">
            We told you! This is super easy to use! install CatCSS or download
            the CSS file that you need and enjoy!
          </p>
        </div>
        <div className="container-center">
          <FaReact size="4em" color="#47c5ae " />
          <FaVuejs size="4em" color="#47c5ae" />
          <FaAngular size="4em" color="#47c5ae" />
          <SiSvelte size="4em" color="#47c5ae" />
        </div>
      </div>
      <div className="container margin-top grid col-2 mid-col-1 small-col-1">
      <div>
        <IoIosSpeedometer size="2em" color="#47c5ae" />
        <h2 className="">Performance</h2>
        <p className="p-midb">
          The best way to optimize your projects, use only what you need!
        </p>
        </div>
        <div className="container-center">
        <img className="img-small" src={performance} alt="speedmobile" />
        </div>
      </div>
      <div className="container margin-top grid col-2 mid-col-1 small-col-1">
      <div>
        <BiWorld size="2em" color="#47c5ae" />
        <h2 className="">Translation into multiple languages!</h2>
        <p className="p-midb">
        At Catcss we want to bring this tool to the whole world, that is why we will have from day one a translation of our page and documentation into English, Spanish, German, Chinese and French.
        </p>
        </div>
        <div className="container-center">
        <img className="img-small" src={translation} alt="speedmobile" />
        </div>
      </div>
      <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  );
}

export default Landing;
