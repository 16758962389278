import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {HiDocumentText} from 'react-icons/hi'
import {AiOutlineSelect} from 'react-icons/ai'
import {AiFillCheckCircle} from 'react-icons/ai'
import {FaArrowsAltH} from 'react-icons/fa'

function FormMenu() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Forms</h1>
        <p className="p-midb">Examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms.</p>
    </div>
    <div className="container">
    <main class="grid col-2 mid-col-2 small-col-1">
            <Link to="Form">
            <div class="card">
            <HiDocumentText size="2em" color="#47c5ae"/><h2>Form Controls</h2>
            <p>Examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms.</p>
            </div>
            </Link>
            <Link to="Select">
            <div class="card">
            <AiOutlineSelect size="2em" color="#47c5ae"/><h2>Select</h2>
            <p>Customize the native <code>&lt;select&gt;</code> with custom CSS that changes the element’s initial appearance.</p>
            </div>
            </Link>
            <Link to="Checkbox">
            <div class="card">
            <AiFillCheckCircle size="2em" color="#47c5ae"/><h2>Checks and radios</h2>
            <p>Create checkboxes and radios easily with our classes.</p>
            </div>
            </Link>
            <Link to="Range">
            <div class="card">
            <FaArrowsAltH size="2em" color="#47c5ae"/><h2>Range</h2>
            <p>Use our custom range inputs.</p>
            </div>
            </Link>
        </main>
    </div>
    
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default FormMenu;