import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';

function Buttons() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Buttons</h1>
        <p className="p-midb">Use Catcss custom button styles for actions in forms, dialogs, and more with support for multiple sizes, states, and more.</p>
    </div>
    <div className="container ">
    <h2 className="color-aqua">Regular buttons</h2>
        <p>Catcss includes several predefined button styles, each serving its own semantic purpose, with a few extras thrown in for more control.
        </p>
        <div className="container-center margin-top grid col-3 mid-col-2 small-col-1">
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn</code></div>
    <button class="btn">Primary</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn green</code></div>
    <button class="btn green ">Secundary</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn pink</code></div>
    <button class="btn pink ">Info</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn yellow</code></div>
    <button class="btn yellow">Warning</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn dark</code></div>
    <button class="btn dark">Dark</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn red</code></div>
    <button class="btn red">Danger</button>
    </div>
    </div>
        </div>
        <div className="container ">
    <h2 className="color-aqua">Outline Buttons</h2>
        <p>In need of a button, but not the hefty background colors they bring? Replace the default modifier classes with the .btn-outline ones to remove all background images and colors on any button
        </p>
        </div>
        <div className="container margin-top grid col-3 mid-col-2 small-col-1">
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn-outline</code></div>
    <button class="btn-outline">Primary</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn-outline green</code></div>
    <button class="btn-outline green ">Secundary</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn-outline pink</code></div>
    <button class="btn-outline pink ">Info</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn-outline yellow</code></div>
    <button class="btn-outline yellow">Warning</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn-outline dark</code></div>
    <button class="btn-outline dark">Dark</button>
    </div>
    <div className="margin-bottom">
    <div className="margin-bottom"><code>btn-outline red</code></div>
    <button class="btn-outline red">Danger</button>
    </div>
    </div>
    <div className="container">
    <p>Some of the button styles use a relatively light foreground color, and should only be used on a dark background in order to have sufficient contrast.</p>
    </div>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Buttons;
