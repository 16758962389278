import React from 'react'
import '../../catcss.css'
import cat from '../../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';

function Checkbox() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Checks and radios</h1>
        <p className="p-midb">Create checkboxes and radios easily with our classes.</p>
    </div>
    <div class="container">
    <h2 className="color-aqua">Checkbox:</h2>
    <div class="container">
    <main class="container">
    <h3 className="margin-bottom">Check:</h3>
    <p>To create a checkbox with the css style already defined use the <code>.form-check-input</code>class</p>
    <div class="form-check">
  <input class="form-check-input margin-bottom" type="checkbox"/> Default checkbox
</div>
 <code className="code2"><p>&lt;class="form-check-input" type="checkbox"/&gt;</p></code>
 <h3 className="margin-bottom">Disabled:</h3>
 <p>Add the <code>disabled</code> boolean attribute on a checkbox to give it a grayed out appearance and remove pointer events.</p>
    <div class="form-check">
  <input class="form-check-input" type="checkbox" disabled/> Disabled checkbox
</div>
<code className="code2"><p>&lt;class="form-check-input" type="checkbox" disabled/&gt;</p></code>
    </main>
    </div>
    </div>
    <div class="container">
    <h2 className="color-aqua">Radios:</h2>
    <div className="container">
    <main class="container">
    <h3 className="margin-bottom">Radio:</h3>
    <p>To create a checkbox with the css style already defined use the <code>.form-check-input</code>class</p>
    <div class="form-check">
    <input class="form-check-input margin-bottom" type="radio" /> Default radio
</div>
<code className="code2"><p>&lt;class="form-check-input" type="radio"/&gt;</p></code>
 <h3 className="margin-bottom">Disabled:</h3>
 <p>Add the <code>disabled</code> boolean attribute on a radio to give it a grayed out appearance and remove pointer events.</p>
    <div class="form-check">
  <input class="form-check-input" type="radio" value="" id="flexCheckDefault" disabled/> Disabled radio
</div>
<code className="code2"><p>&lt;class="form-check-input" type="radio" disabled/&gt;</p></code>
    </main>
    </div>
    </div>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Checkbox;