import React from 'react'
import '../../../catcss.css';
import cat from '../../../images/logocontitulo(1).png';
import responsive from '../../../images/responsive.svg';
import { Link } from 'react-router-dom';


function Images() {
    return ( 
     <div className="container">
      <div className="container container-flexend ">
      <Link to="/">
        <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
        </Link>
      </div>
      <div className="container margin-top">
        <h1>Images</h1>
        <p className="p-midb">Documentation and examples for opting images into responsive behavior (so they never become larger than their parent elements) and add lightweight styles to them—all via classes.</p>
    </div>
    <div className="container  ">
    <h2 className="color-aqua">Responsive images</h2>
        <p>Images in Catcss are made responsive with <code>.img-resp </code> This applies <code>max-width: 100%;</code> and <code>height: auto;</code> to the image so that it scales with the parent element.
        </p>
        </div>
        <div class="container-bd-example card2">
        <img className="img-resp"  src={responsive} alt="responsiveexample"/> 
        </div>
        <div class="container-form">
        <code>&lt;img src="..." class="img-resp" alt="..."&gt;</code>
        </div>
        <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
      </div>
    )
  }
  
  export default Images;