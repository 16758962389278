import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'

function Spinners() {
  return ( 
   <div className="container">
   <Helmet>
      <title>Catcss - Spinners</title>
      <meta name="description" content="Create spinners with Catcss"></meta>
    </Helmet>
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Spinners</h1>
        <p className="p-midb">Indicate the loading state of a component or page with Bootstrap spinners, built entirely with HTML, CSS, and no JavaScript.</p>
    </div>
    <div className="container">
    <div className="container">
    <h3>Default</h3>
    <p>The following spinners are made by the Catcss team, to use it you just have to use the following classes <code>spinner</code> , <code>spinner-dot</code> and <code>spinner-lines</code> </p>
    </div>
    <div className="container-bd-example grid col-3 mid-col-3 small-col-1">
    <div class="spinner"><div></div><div></div><div></div><div></div></div>
    <div class="spinner-dot"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <div class="spinner-lines"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    </div>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Spinners;