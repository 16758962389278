import React from 'react'
import '../../catcss.css'
import cat from '../../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';


function Range() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Range</h1>
        <p className="p-midb">Use our custom range inputs for consistent cross-browser styling and built-in customization.</p>
    </div>
    <div class="container">
    <main class="container">
    <div class="container">
    <h3>Default:</h3>
    <p>Our custom <code>&lt;input type = "range"&gt;</code> already comes with color and design values that can be easily customized to use according to your needs.</p>
    <div className="container ">
    <input type="range" />
    <code className="code2"><p>&lt;input type = "range"/&gt;</p></code>
    </div>
</div>
<div class="container">
    <h3>Disabled:</h3>
    <p>Our custom disabled  <code>&lt;input type = "range"&gt;</code> is already prepared using simply the disabled <code>boolean</code> attribute .</p>
    <div className="container ">
    <input type="range"  disabled/>
    <code className="code2"><p>&lt;input type = "range" disabled/&gt;</p></code>
    </div>
</div>
<div class="container">
    <h3>Min and max:</h3>
    <p>Range inputs have implicit values for <code>min</code> and <code>max</code>  from <code>0</code>  to <code>100</code> , respectively. You may specify new values for those using the min and max attributes.</p>
    <div className="container ">
    <input type="range" min="0" max="5" />
    <code className="code2"><p>&lt;input type = "range" min="0" max="5" /&gt;</p></code>
    </div>
</div>
<div class="container">
    <h3>Steps:</h3>
    <p>By default, range inputs “snap” to integer values. To change this, you can specify a <code>step</code>value. In the example below, we double the number of steps by using <code>step="0.5"</code> .</p>
    <div className="container ">
    <input type="range" min="0" max="5"step="0.5"/>
    <code className="code2"><p>&lt;input type = "range" min="0" max="5" /&gt;</p></code>
    </div>
</div>
</main>
    </div>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Range;