import React from 'react'
import './map.css'
import '../../catcss.css'
import cat from '../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'

function Map() {
  return (
    <div className="container">
    <Helmet>
      <title>Catcss - Roadmap</title>
      <meta name="description" content="Check the Catcss Roadmap"></meta>
    </Helmet>
   <div className="container container-flexend ">
   <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container">
      <p className="p-midb">The easiest to use CSS framework that saves you time!</p>
    </div>
    <ul class="timeline">
  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <p class="timeline-event-thumbnail">December, 22, 2021</p>
      <h3>Hello World!</h3>
      <h4>Catcss version 0.1 released</h4>
      <p className="p-grey">At this point in the roadmap we will have a basic version of what our framework will be in the future, we will give access to it to some developers so that they can give us feedback on the functioning and possible improvements of the framework.</p>
    </div>
  </li>
  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <p class="timeline-event-thumbnail">February 2022</p>
      <h3>We need you!</h3>
      <h4>Version 0.5</h4>
      <p className="p-grey">In this version we will open the doors to all those developers who want to test our framework and want to contribute to improving it. We will also have included the improvements after listening to the comments of the first version.</p>
    </div>
  </li>
  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <p class="timeline-event-thumbnail">July 2022</p>
      <h3>The day has finally come!</h3>
      <h4>Catcss version 1.0</h4>
      <p className="p-grey">With all the help received by you, our work and all the improvements implemented after listening to you, in July 2022 we will launch our first full version of Catcss, this does not mean that it will be the last, we will continue to improve and update our and your framework to make the design web a super easy and fun task to do.</p>
    </div>
  </li>
</ul>
<div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
</div>
  
  )
}

export default Map;
