import React from 'react'
import '../../catcss.css';
import cat from '../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import {RiRoadMapFill} from 'react-icons/ri';
import {CgFileDocument} from 'react-icons/cg';
import { MdFiberNew } from "react-icons/md";
import { FaCode } from 'react-icons/fa';
import { FaCat } from 'react-icons/fa';
import { FaPatreon } from "react-icons/fa";


function GetStarted() {
    return ( 
     <div className="container">
      <Helmet>
      <title>Catcss - Getting started</title>
      <meta name="description" content="Read the catcss documentation or meet our team"></meta>
    </Helmet>
      <div className="container container-flexend ">
      <Link to="/">
        <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
        </Link>
      </div>
      <div className="container margin-top">
        <h1>Getting started with Catcss</h1>
        <p className="p-midb">Learn Catcss the way that best matches your learning style.</p>
    </div>
    <div className="container">
        <main class="grid col-4 mid-col-2 small-col-1">
          <Link to="/News">
          <div class="card">
            <MdFiberNew size="2em" color="#47c5ae" />
            <h2>What's new in Catcss</h2>
            <p>Stay up to date with the latest updates and news about Catcss.</p>
          </div>
          </Link>
          <Link to="/Map">
          <div class="card">
            <RiRoadMapFill size="2em" color="#47c5ae" />
            <h2>Roadmap</h2>
            <p>Take a look at our roadmap, it keeps getting updated!</p>
          </div>
          </Link>
          <Link to="/Team">
          <div class="card">
            <FaCat size="2em" color="#47c5ae" />
            <h2>Team</h2>
            <p>
            Meet who is behind Catcss!
            </p>
          </div>
          </Link>
          <Link to="/Apply">
          <div class="card">
            <FaCode size="2em" color="#47c5ae" />
            <h2>Apply for alpha!</h2>
            <p>Join our group of beta testers and enjoy the version 0.1 of Catcss in December.</p>
          </div>
          </Link>
        </main>
        </div>
        <div class="container">
    <h2 className="color-aqua">Thanks to our backers</h2>
    <p>Without the help of the people and companies that collaborate in this project, it would be impossible to continue updating and developing Catcss, a project like this needs full-time dedication, so if you want to collaborate with us by supporting us financially, you can check the following section to find out, thank you for making it possible for this project to move forward!</p>

    </div>
    <main>
          <Link to="/Backers">
          <div class="container card">
            <FaPatreon size="2em" color="#47c5ae" />
            <h2>Support us!</h2>
            <p>In order to continue developing and updating Catcss it is necessary to work on it full time, so we appreciate all the financial help that users and companies that use it can give us. This aid will be used to pay office rent and the expenses that this entails in addition to trying to make a living from this project, thank you very much to all of you: 3</p>
          </div>
          </Link>
        </main>
        <div className="container-center ">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
      </div>
    )
  }
  
  export default GetStarted;