import React from 'react'
import '../../catcss.css'
import cat from '../../images/logocontitulo(1).png'
import hoppy from './hoppy.png'
import { Link } from 'react-router-dom';
import {SiPaypal} from 'react-icons/si';
import {Helmet} from 'react-helmet';


function Backers() {
  return ( 
   <div className="container">
   <Helmet>
      <title>Catcss - Backers</title>
      <meta name="description" content="Catcss backers"></meta>
    </Helmet>
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    
    <div className="container margin-top">
        <h1>Sponsor Catcss Development</h1>
        <p className="p-midb">Catcss is a free to use css framework, however the amount of effort to maintain and update it with new features needs financial support, you can support us with the following methods:</p>
    </div>
    <div className="container ">
    <h2 className="color-aqua">One-time Donations</h2>
        <p>We accept payment through the paypal platform for one-time payments.
        </p>
        <a href="https://www.paypal.com/donate/?business=7XNKWEV8ADWTQ&no_recurring=0&currency_code=EUR">
  <SiPaypal color="#47c5ae" size="3em"/>
</a>
        </div>
        <div className="container margin-top ">
    <h2 className="color-aqua">Recurring pledges</h2>
        <p>Recurring pledges come with exclusive perks, e.g. having your name listed in the Catcss GitHub repository, or have your company logo placed on this website. 
        </p>
        <a href="https://www.patreon.com/catcss">
          <p className="color-aqua">Become a backer or sponsor via Patreon</p>
        </a>
        <p>With this you can help me a lot to continue working on this project and try to create together with you the most intuitive css framework in the world, this belongs to everyone.</p>
        </div>
        <div className="container ">
    <h2 className="color-aqua margin-bottom">Current Sponsors</h2>
        <a href="http://hoppyapi.com" target="_blank" rel="noreferrer">
          <img className="sponsorlogo " src={hoppy} alt="hoppy"/>
        </a>
        </div>
        <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Backers;