import React from 'react'
import '../../catcss.css'
import cat from '../../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';

function Form() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    
    <div className="container margin-top">
        <h1>Forms</h1>
        <p className="p-midb">Examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms.</p>
    </div>
   
    <div class="container ">
    <h2 className="color-aqua">Form controls:</h2>
    <div class="container">
    <main class="container">
    <h3>Sizing:</h3>
    <p>Set heights using classes like .form-control lg and .form-control sm.</p>
    <form>
    <input class="form-control lg margin-bottom" type="text"  placeholder="form-control-lg"/>
    <input class="form-control margin-bottom" type="text"  placeholder="Default size"/>
    <input class="form-control sm " type="text"  placeholder="form-control sm"/>
    </form>
    <code className="code2"><p>&lt;input class="form-control form-control-lg" type="text"  placeholder="form-control-lg"/&gt;</p></code>
    <code className="code2"><p>&lt;input class="form-control" type="text"  placeholder="form-control"/&gt;</p></code>
    <code className="code2"><p>&lt;input class="form-control sm" type="text"  placeholder="form-control sm"/&gt;</p></code>
    </main>
    </div>
    <div class="container">
    <main class="container">
    <h3>Disabled:</h3>
    <p>Add the <code>disabled</code>boolean attribute on an input to give it a grayed out appearance and remove pointer events.</p>
    <form>
    
    <input class="form-control" type="text" placeholder="Disabled input" aria-label="Disabled input example" disabled/>
    </form>
    <code className="code2"><p>&lt;input class="form-control" type="text" placeholder="Disabled input" aria-label="Disabled input example" disabled/&gt;</p></code>
    </main>
    </div>
    <div class="container">
    <main class="container">
    <h3 className="margin-bottom">File input:</h3>
    <form>
    <p>Default file input example</p>
    <input class="form-control margin-bottom" type="file" />
    <p>Disabled file input example</p>
    <input class="form-control margin-bottom" type="file" disabled/>
    <p>Small file input example</p>
    <input class="form-control sm margin-bottom" type="file" />
    <p>Large file input example</p>
    <input class="form-control lg margin-bottom" type="file" />
    </form>
    </main>
    </div>
    <div class="container">
    <main class="container">
    <h3 className="margin-bottom">Color picker:</h3>
    <form>
  <input type="color" value="#47c5ae"></input>
    </form>
    </main>
    </div>
    </div>
    
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Form;