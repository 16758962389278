import React from 'react'
import '../../../catcss.css';
import cat from '../../../images/logocontitulo(1).png';
import responsive from '../../../images/responsive.svg';
import { Link } from 'react-router-dom';


function Tables() {
    return ( 
     <div className="container">
      <div className="container container-flexend ">
      <Link to="/">
        <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
        </Link>
      </div>
      <div className="container margin-top">
        <h1>Tables</h1>
        <p className="p-midb">Documentation and examples for styling of tables.</p>
    </div>
    <div className="container  ">
    <h2 className="color-aqua">Tables</h2>
        <p>In Catcss we also try to change the native styles of different tags, in this case this would be the style that you would have natively using the <code>table</code>, <code>td</code> and <code>th</code> tag
        </p>
        </div>
        <div class="container">
    <main class="container">
    <div class="container">
    <table>
  <thead>
    <tr>
      <th>#</th>
      <th>First</th>
      <th>Second</th>
      <th>Third</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Frank</td>
      <td>Evan</td>
      <td>Steve</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>JC</td>
      <td>Alexia</td>
      <td>Juno</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td colspan="2">Pontones</td>
      <td>Dani</td>
    </tr>
  </tbody>
</table>
    </div>
    <div class="container">
    <div className="container  ">
    <h2>Changing the table colors</h2>
        <p>In Catcss change the background of your <code>table</code> elements is super easy, you just need to check the colors documentation section and write the color you want to use in the class, in this case we are using different colors with the same intensity, the class would be like this <code>bg-color *-200</code>
        </p>
        </div>
    <table>
  <thead>
    <tr>
      <th>#</th>
      <th class="bg-color green-200">First</th>
      <th class="bg-color green-200">Second</th>
      <th class="bg-color green-200">Third</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td class="bg-color blue-200">Frank</td>
      <td class="bg-color blue-200">Evan</td>
      <td class="bg-color blue-200">Steve</td>
    </tr>
    <tr>
      <th scope="row" >2</th>
      <td class="bg-color pink-200">JC</td>
      <td class="bg-color pink-200">Alexia</td>
      <td class="bg-color pink-200">Juno</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td class="bg-color red-200">Pontones</td>
      <td class="bg-color red-200">Dani</td>
      <td class="bg-color red-200">Oneto</td>

    </tr>
  </tbody>
</table>
<div class="container">
<code className="code2 margin-bottom">
&lt;th&gt;#&lt;th/&gt;<br></br>
&lt;th class="bg-color green-200"&gt;First&lt;th/&gt;<br></br>
&lt;th class="bg-color green-200"&gt;Second&lt;th/&gt;<br></br>
&lt;th class="bg-color green-200"&gt;Third&lt;th/&gt;<br></br>
&lt;th&gt;1&lt;/th&gt;<br></br>
&lt;td class="bg-color blue-200"&gt;Frank&lt;td/&gt;<br></br>
&lt;td class="bg-color blue-200"&gt;Evan&lt;td/&gt;<br></br>
&lt;td class="bg-color blue-200"&gt;Steve&lt;td/&gt;<br></br>
&lt;th&gt;2&lt;/th&gt;<br></br>
&lt;td class="bg-color pink-200"&gt;JC&lt;td/&gt;<br></br>
&lt;td class="bg-color pink-200"&gt;Alexia&lt;td/&gt;<br></br>
&lt;td class="bg-color pink-200"&gt;Juno&lt;td/&gt;<br></br>
&lt;th&gt;3&lt;/th&gt;<br></br>
&lt;td class="bg-color red-200"&gt;Pontones&lt;td/&gt;<br></br>
&lt;td class="bg-color red-200"&gt;Dani&lt;td/&gt;<br></br>
&lt;td class="bg-color red-200"&gt;Oneto&lt;td/&gt;<br></br>
</code>
</div>
    </div>
    
</main>
    </div>
        <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
      </div>
    )
  }
  
  export default Tables;