import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {GiHamburgerMenu} from 'react-icons/gi';
import {GoAlert} from 'react-icons/go'
import {MdLabel} from 'react-icons/md'
import {FaRegHandPointer } from 'react-icons/fa'
import {FaAddressCard} from 'react-icons/fa'
import {BiCarousel} from 'react-icons/bi'
import {BiNotification} from 'react-icons/bi'
import {AiOutlineClose} from 'react-icons/ai'
import {IoIosArrowDropdownCircle} from 'react-icons/io'
import {FaSpinner} from 'react-icons/fa'

function ComponentMenu() {
  return ( 
   <div className="container">
   <Helmet>
      <title>Catcss Components</title>
      <meta name="description" content="Components in our catcss framework"></meta>
    </Helmet>
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Components</h1>
        <p className="p-midb">Examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms.</p>
    </div>
    <div className="container">
    <main class="grid col-2 mid-col-2 small-col-1">
            <Link to="Accordion">
            <div class="card">
            <GiHamburgerMenu size="2em" color="#47c5ae"/><h2>Accordion</h2>
            <p>Build vertically collapsing accordions.</p>
            </div>
            </Link>
            <Link to="Alerts">
            <div class="card">
            <GoAlert size="2em" color="#47c5ae"/><h2>Alerts</h2>
            <p>Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.</p>
            </div>
            </Link>
            <Link to="Badges">
            <div class="card">
            <MdLabel size="2em" color="#47c5ae"/><h2>Badges</h2>
            <p>Documentation and examples for badges, our small count and labeling component.</p>
            </div>
            </Link>
            <Link to="Buttons">
            <div class="card">
                <FaRegHandPointer size="2em" color="#47c5ae"/>
                <h2>Buttons</h2>
                <p>Create buttons in a simple and intuitive way</p>
            </div>
            </Link>
            <Link to="Cards">
            <div class="card">
                <FaAddressCard size="2em" color="#47c5ae"/>
                <h2>Cards</h2>
                <p>Create cards fast, we have plenty prefabs designs for you!</p>
            </div>
            </Link>
            <Link to="Colors">
            <div class="card">
                <BiCarousel size="2em" color="#47c5ae"/>
                <h2>Carousel</h2>
                <p>A slideshow component for cycling through elements—images or slides of text—like a carousel.</p>
            </div>
            </Link>
            <Link to="Closebutton">
            <div class="card">
                <AiOutlineClose size="2em" color="#47c5ae"/>
                <h2>Close Button</h2>
                <p>A generic close button for dismissing content like modals and alerts.</p>
            </div>
            </Link>
            <Link to="Colors">
            <div class="card">
                <IoIosArrowDropdownCircle size="2em" color="#47c5ae"/>
                <h2>Dropdowns</h2>
                <p>Toggle contextual overlays for displaying lists of links and more.</p>
            </div>
            </Link>
            <Link to="Spinners">
            <div class="card">
                <FaSpinner size="2em" color="#47c5ae"/>
                <h2>Spinners</h2>
                <p>The easiest way to create spinners for your website or app</p>
            </div>
            </Link>
            <Link to="Colors">
            <div class="card">
                <BiNotification size="2em" color="#47c5ae"/>
                <h2>Toasts</h2>
                <p>Push notifications to your visitors with a toast, a lightweight and easily customizable alert message.</p>
            </div>
            </Link>
        </main>
    </div>
    
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default ComponentMenu;