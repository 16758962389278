import React from 'react'
import '../../catcss.css';
import cat from '../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import { FaTwitter } from "react-icons/fa";
import {MdWork} from 'react-icons/md';
import {MdLocationOn} from 'react-icons/md';
import profile from './prof1.png';
import { FaGithubAlt} from "react-icons/fa";

function Team() {
    return ( 
     <div className="container">
     <Helmet>
      <title>Catcss - Meet the team</title>
      <meta name="description" content="Meet whos is developing Catcss"></meta>
    </Helmet>
      <div className="container container-flexend ">
      <Link to="/">
        <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
        </Link>
      </div>
      <div className="container margin-top">
        <h1>Meet the team</h1>
        <p className="p-midb">The development of Catcss right now is being led by just one person, but the intention is that in the future opportunities will open up for new developers to participate in this exciting journey.</p>
    </div>
    <div className="container-profile">
        <div className="container grid col-2 mid-col-1 small-col-1">
            <div className="imagediv">
            <img className="profileimage" src={profile} alt="fran"/>
            </div>
            <div className="container-start">
          <div className="margin-bottom">
            <div className="margin-bottom">
              <h3>Fran Ruiz Santaclara</h3>
            </div>
            <div className="profile-line"><MdWork color="#47c5ae"/>&nbsp;
                <p >Creator Catcss</p>
            </div>
            <div className="profile-line"><MdLocationOn color="#47c5ae"/>&nbsp;
                <p >Cádiz, Spain</p>
            </div>
            <div className="profile-line">
            <a href="https://twitter.com/RuizSantaclara" target="_blank" rel="noreferrer">
              <FaTwitter color="#47c5ae" size="1.7em" />
          </a> &nbsp;
          <a href="https://github.com/Franperiyeah" target="_blank" rel="noreferrer">
            
              <FaGithubAlt color="#47c5ae" size="1.7em" />
              
          </a>
            </div>
          </div>
          </div>
        </div>
    </div>
    <div className="container">
    <p>Born in Cádiz, southern Spain, front-end developer and lover of design, illustration and technology.
I love minimalist designs and I try to bring that philosophy to this framework as well.</p>
    </div>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
      </div>
    )
  }
  
  export default Team;