import React from 'react'
import '../catcss.css'
import cat from '../../../images/logocontitulo(1).png'
import { Link } from 'react-router-dom';
import {FaPalette} from 'react-icons/fa';
import {AiOutlineBorderOuter} from 'react-icons/ai'
import {BiColumns} from 'react-icons/bi'
import {CgOverflow } from 'react-icons/cg'
import {CgEditShadows} from 'react-icons/cg'
import {IoResize} from 'react-icons/io5'
import {BiNotification} from 'react-icons/bi'
import {FiFileText} from 'react-icons/fi'
import {FaSpinner} from 'react-icons/fa'
import { CgFontSpacing  } from "react-icons/cg";

function Utilities() {
  return ( 
   <div className="container">
    <div className="container container-flexend ">
    <Link to="/">
      <img className=" col-1 mid-col-1 small-call-1 img-small"  src={cat} alt="catcss"/> 
      </Link>
    </div>
    <div className="container margin-top">
        <h1>Utilities</h1>
        <p className="p-midb">Examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms.</p>
    </div>
    <div className="container">
    <main class="grid col-2 mid-col-2 small-col-1">
    <Link to="Select">
            <div class="card">
            <AiOutlineBorderOuter size="2em" color="#47c5ae"/><h2>Borders</h2>
            <p>Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons, or any other element.</p>
            </div>
            </Link>
             <Link to="Colors">
            <div class="card">
                <FaPalette size="2em" color="#47c5ae"/>
                <h2>Colors</h2>
                <p>Customize your text and backgrounds with our extense palette of colors</p>
            </div>
            </Link>   
            <Link to="Checkbox">
            <div class="card">
            <BiColumns size="2em" color="#47c5ae"/><h2>Flex</h2>
            <p>Quickly manage the layout, alignment, and sizing of grid columns, navigation, components, and more with a full suite of responsive flexbox utilities. For more complex implementations, custom CSS may be necessary.</p>
            </div>
            </Link>
            <Link to="Buttons">
            <div class="card">
                <CgOverflow size="2em" color="#47c5ae"/>
                <h2>Overflow</h2>
                <p>Use these shorthand utilities for quickly configuring how content overflows an element.</p>
            </div>
            </Link>
            <Link to="Colors">
            <div class="card">
                <CgEditShadows size="2em" color="#47c5ae"/>
                <h2>Shadows</h2>
                <p>Add or remove shadows to elements with box-shadow utilities.</p>
            </div>
            </Link>
            <Link to="Colors">
            <div class="card">
                <IoResize size="2em" color="#47c5ae"/>
                <h2>Sizing</h2>
                <p>Easily make an element as wide or as tall with our width and height utilities.</p>
            </div>
            </Link>
            <Link to="Colors">
            <div class="card">
                <CgFontSpacing  size="2em" color="#47c5ae"/>
                <h2>Spacing</h2>
                <p>In Catcss we have a wide range of shorthand responsive classes to modify an element's appearance</p>
            </div>
            </Link>
            <Link to="Colors">
            <div class="card">
                <FiFileText size="2em" color="#47c5ae"/>
                <h2>Text</h2>
                <p>Documentation and examples for common text utilities to control alignment, wrapping, weight, and more.</p>
            </div>
            </Link>
        </main>
    </div>
    <div className="container-center margin-top">
      <footer> Made by <a className="txt-color green-400" href="https://twitter.com/RuizSantaclara">@RuizSantaclara</a>. </footer> 
      </div>
    </div>
  )
}

export default Utilities;